import React, { Component } from 'react';
import './CP.css'
import Pager from '../components/Pager.js';
import { Helmet } from "react-helmet";
import Helpers from '../Helpers';

class CP extends Component {

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    var modal = document.getElementById('myModal');

    var img = document.getElementsByClassName('myImg');
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    for (var i = 0; i < img.length; i++) {
      const curImg = img[i];
      curImg.addEventListener('click', function () {
        modal.style.display = "block";
        var newSrc = this.src;
        modalImg.src = newSrc;
        captionText.innerHTML = this.alt;
      });
    }

    var span = document.getElementsByClassName("close")[0];
    span.onClick = function () {
      modal.style.display = "none";
    }
  }

  closeModal() {
    document.getElementById('myModal').style.display = 'none';
  }

  render() {
    const items = [
      {
        src: "images/screen1.png",
        altText: 'Slide 1',
        caption: 'Slide 1'
      },
      {
        src: "images/screen2.png",
        altText: 'Slide 2',
        caption: 'Slide 2'
      },
      {
        src: "images/screen3.png",
        altText: 'Slide 3',
        caption: 'Slide 3'
      }
    ];

    return (
      <div>
        <Helmet>
          <title>KW-UPLOADS - Command Post</title>
          <meta name="description" content="The ultimate app for C&C3 Kane's Wrath content" />
          <meta name="keywords" content="Kane's Wrath, KW, C&C3, Command & Conquer 3, Command Post, patches, maps, mods, replays, tournaments" />
        </Helmet>
        <div className='w3-margin'>
          <div className='center'>
              <h1><span className='wt-500'>Command Post</span></h1>
              <h3>The ultimate app for C&C3 KW content</h3>
              <h2><a href={Helpers.CP_URL}>Download Here [With Installer]</a></h2>
              <h3><a href={Helpers.CP_ALT_URL}>Alternate Download Link</a></h3>
              <h7 style={{color: 'red', margin: '15px'}}>
                <div>
                  If this download is blocked, please whitelist this site on your anti-virus. You may also be required to allow/whitelist the installer and program itself on your anti-virus. The alerts are misleading and a false positive.
                </div>
                <div>
                  For more information, read the <a href='#faq-security'>Security/Anti-Virus FAQs</a>.
                </div>
              </h7>
              <h7 style={{margin: '10px'}}>
                <div>
                    Read the <a href='#faq'>FAQ section</a> for other troubleshooting issues or general queries.
                </div>
              </h7>
          </div>
          <br />
          <div>
            <p className='wt-500'>What is Command Post?</p>
            <p>Command Post is a highly versatile, comprehensive desktop application/program for C&amp;C3 Kane&apos;s Wrath players. Its primary task is to deeply integrate with your Kane's Wrath installation and help manage your replays, patches and deliver KW-related content in a meaningful, precise, convenient and personalised manner.</p>
            <p>
              <div style={{fontSize: '24px'}}>Are you a...</div>
              <ul>
                <li><span className='wt-500'>Casual Kane's Wrath fan?</span> Easily install maps/patches and keep them automatically updated. Find your favourite expert/players' replays - watch, analyse and share them with ease. Hassle-free management of patch versions make it a breeze to watch replays from any era. Stay updated on community news/events.</li>
                <li><span className='wt-500'>Competitive Player?</span> Command Post features regular tournaments and ladders to keep you challenged!</li>
                <li><span className='wt-500'>Map/Mod Creator?</span> Command Post enables you easily create and publish your content, and allows you to update them at will - while ensuring users always have your latest and greatest version!</li>
              </ul>
            </p>
            <p><div style={{fontSize: '18px'}}>Command Post currently features the following:</div>
              <ul>
                <li><span className='wt-500'>Replay Browser:</span> Browse all your saved replays with relevant information. Allows easy renaming of replays, adding replay descriptions, drag-and-dropping replays to/from other applications/folders.</li>
                <li><span className='wt-500'>Patch Updater:</span> Keep your patches/maps updated automatically; find and switch between older versions easily.</li>
                <li><span className='wt-500'>Patch Recognition:</span> Precisely identify and acquire mappacks/patches required to watch a specific replay.</li>
                <li><span className='wt-500'>Replay Uploads:</span> Share your replays by uploading to the Command Post replay system.</li>
                <li><span className='wt-500'>Replay Analyser:</span> Analyse replays for details of player actions -- including build orders, APM counts etc.</li>
                <li><span className='wt-500'>Map Browser:</span> Command Post displays all your custom maps and keeps them auto-updated with the latest versions.</li>
                <li><span className='wt-500'>Upload Maps:</span> Share your maps, along with minimaps, descriptions and screenshots.</li>
                <li><span className='wt-500'>Automated Tournaments System:</span> Host, sign up and play in tournaments with ease. Replays/results and brackets are updated automatically with no user input required.</li>
                <li><span className='wt-500'>Match History:</span> Command Post tracks your online matches and maintains a match history. Look up your winrate based on specific faction picks (including randomed factions), opponents, match length and more!</li>
                <li><span className='wt-500'>Recent Replays:</span> Match History also automatically saves all Command Post users' recent replays for 3-4 days - these replays are publicly accessible, provided the user has provided their consent. They can be permanently uploaded or downloaded should you wish to keep them.</li>
                <li><span className='wt-500'>News Updates:</span> Stay updated/notified on the latest community news/events.</li>
                <li><span className='wt-500'>User Messaging:</span> See whether other Command Post users online and/or playing the game; send private messages to other users.</li>
                <li><span className='wt-500'>VPN Servers:</span> Connect to available VPN Servers registered on Command Post for LAN/Network play. With its easy setup via OpenVPN, Command Post offers a superior KW-focused alternative to Gameranger/Hamachi.</li>
                <li><span className='wt-500'>Troubleshooter Tool:</span> Fix problems with your KW installation such as registry errors with this comprehensive yet simple tool!</li>
                <li><span className='wt-500'>Easy Sharing via Links:</span> Nearly all content in Command Post can be conveniently shared via links with other Command Post users. Click the blue links to get links for any replay, patch, map etc.</li>
                <li><span className='wt-500'>... And more features soon to come!</span></li>
              </ul>
            </p>
          </div>

          <div>
            <img className="myImg" src="images/screen1.png" alt="Manage and keep Patches/Mappacks updated" />
            <img className="myImg" src="images/screen2.png" alt="Switch between different Mappack versions" />

            <img className="myImg" src="images/screen3.png" alt="Browse all your saved replays with key details, including randomed factions" />
            <img className="myImg" src="images/screen4.png" alt="Command Post auto-detects the required Mappack for watching any replay" />
            <img className="myImg" src="images/screen5.png" alt="Share your replays with others by uploading to the Command Post replay system" />
            
            <img className="myImg" src="images/screen6.png" alt="Auto-save replays as you play, renaming them automatically by players/factions/map/timestamp etc" />
            <img className="myImg" src="images/screen7.png" alt="Analyse replays for build orders, actions and APM. Use filters for detailed breakdowns of actions." />
            <img className="myImg" src="images/screen8.png" alt="Look up APM graphs for players throughout the match." />

            <img className="myImg" src="images/screen9.png" alt="Command Post is a convenient portal to download and stay updated with KW&apos;s most popular patches" />

            <img className="myImg" src="images/screen10.png" alt="Communicate with and look up other Command Post users&apos; profiles, including their replays and matches" />
            <img className="myImg" src="images/screen11.png" alt="Manage your custom maps - delete, upload your maps and stay updated with the latest maps from others." />
            <img className="myImg" src="images/screen12.png" alt="Share your maps by uploading - allowing others to download and comment on your maps." />

            <img className="myImg" src="images/screen13.png" alt="Command Post records your match history, allowing you to search based on various metrics - faction (inc. randomed), match duration, opponents etc. It also places you in a ranking ladder when playing against other Command Post users. Command Post&apos;s 1v1 ladders are based on ELO." />
            <img className="myImg" src="images/screen14.png" alt="Look up player statistics and activity - featuring informative charts" />
            <img className="myImg" src="images/screen15.png" alt="Easy-to-use tournament system: host and play tournaments/challenges with others; featuring auto-recording and uploading of replays and auto-updation of brackets" />
            <img className="myImg" src="images/screen16.png" alt="Easy-to-use tournament system: host and play tournaments/challenges with others; featuring auto-recording and uploading of replays and auto-updation of brackets" />
            <img className="myImg" src="images/screen17.png" alt="Features OpenVPN-based Network/LAN mode; a much more hassle-free alternative to Hamachi/Gameranger" />

            <div id="myModal" className="modal" onClick={this.closeModal}>
              <span className="close" onClick={this.closeModal}>&times;</span>
              <img className="modal-content" id="img01" />
              <div>NEXT</div>
              <div id="caption"></div>
            </div>
          </div>

          <br />

          <div id='faq' className='wt-700 faq-title'>
            <a href='#faq'>FAQs (Frequently Asked Questions)</a>
          </div>

          <div className='faq-subtitle'>
            <ul>
              <li><a href='#faq-general'>General</a></li>
              <li><a href='#faq-troubleshooting'>Troubleshooting</a></li>
              <li><a href='#faq-security'>Security/Anti-Virus</a></li>
            </ul>
          </div>

          <br />

          <div id='faq-general' className='wt-700 faq-subtitle'>
            <p>
              <a href='#faq-general'>General</a>
            </p>
          </div>
                                    
          <div>
            <p className='wt-500'>How do I install Command Post?</p>
            <p>Simply run the installer which you can download from <a href="http://cgf-uploads.net/production/public/files/CommandPostInstaller.zip">here</a>.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>What is the purpose of Command Post, and what problems does it address?</p>
            <p>Replays, Custom Maps, Mods/Patches and Compiled/Mod Maps are the 4 kinds of shareable user content in KW. Custom Maps are the most easily distributable form of content in the game, thanks to the in-game map transfer feature.
              Unfortunately, no in-game functionality exists to easily manage and share Replays, Mods/Patches and Mod Maps. The problem is further compounded by Replays from different Mods/Patches or Mod Maps; there exists no reliable
                way of figuring out which Mod or Map is required to watch a Replay (with wrong ones leading to replay desyncs)! Likewise, Mod Maps (or compiled Map Packs), are not transferrable in-game either, which is a huge pity.</p>
            <p>Command Post is a one-stop portal for all the above 4 types of content, allowing you manage your locally saved content and upload them in public for others to download. Most importantly, it automatically keeps your Maps/Mods updated to the latest available versions, 
                and also precisely installs the required Maps/Mods to watch replays.
                While managing user content such as Patches/Maps/Replays is the prime focus of Command Post, it has several other neat features - Replay Analysis, automated tournaments, match history recording, player statistics, ELO-based ladders with points, VPN Servers for LAN play and much more.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>I know of Replays, Custom Maps or Patches... but what exactly are Mod Maps?</p>
            <p>Mod Maps (or Compiled Maps) are Custom Maps on steroids, allowing you to add or modify your own game assets (units, powers, textures/models etc) into your maps. Mod-Maps combine Mod/Patch changes into Maps that can be played on the
              vanilla 1.02 Patch without requiring you to switch between Patches (and thereby being incompatible/greyed out from joining others&apos;rooms online). Despite these immensely useful qualities, very few Mod-Maps have ever been created
            for KW purely because they are not transferrable in-game, forcing players to download and install them manually from outside, which can be a painful/time-consuming experience. The 1.02+ Map Packs are a prime example of Map Packs that
          have suffered playerbase friction caused by newer updates/releases not being readily available or distributed to users.</p>
            <p>Command Post addresses these problems by being an easy-to-use, single portal to install, update and manage all your Mod Maps.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Is this a replacement for C&amp;C-Online/Revora to play multiplayer games?</p>
            <p>Command Post is a standalone service and is <b>NOT a replacement for C&amp;C-online/Revora</b>, nor is it affiliated with any other party. To play online, you will require C&amp;C-Online/Revora regardless of Command Post (Please visit <a href="https://cnc-online.net">cnc-online.net</a> to setup your account there).</p>
            <p>However, Command Post does provide VPN services to allow you to enjoy multiplayer with your buddies over LAN.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Can uploaded replays or mods on Command Post be shared by links?</p>
            <p>Yes, you can share links to replays (or any other content hosted on Command Post - including Patches/Maps, Tournaments, Match History etc) with other Command Post users. If you click the blue ID link for an uploaded replay (or any other content), the link will be copied to clipboard and may be shared with others. </p>
            <p>When pasted in Command Post's "Search All" bar (at the top-right corner), OR in a web browser&apos;s address bar (Note: Browser may ask for permission to open the link on Command Post), the link directs the user to that content on Command Post.</p>
            <img src="images/cp_share.png" />
          </div>

          <br />

          <div>
            <p className='wt-500'>I want to create my own mappack with changes applied from the 1.02+ Patch. Is this possible via Command Post?</p>
            <p>Yes! Command Post's <a href="https://cgf-uploads.net/redirect?url=commandpost%3a%2f%2ftools%2fmodding%2fmodmapbuilder" target='_blank'>ModMap Builder</a> makes it easy to compile your own mappacks with downlaodable asset packs. This allows you to build assets such as Weather (snow/rain), fancy Tib crystals or 1.02+'s changes/bugfixes, or various other assets into your own maps.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>How does Command Post&apos;s player rank/ladder system work?</p>
            <p>Currently available ladders include those for 1v1 and 2v2, with one each for both online and network modes. A match is considered for rank points calculation only when ALL participants of a match are Command Post users. Ladders for 1v1 use ELO for points calculation.</p>
          </div>

          <br />
          
          <div>
            <p className='wt-500'>Does Command Post require an Internet connection to work?</p>
            <p>Command Post requires an Internet connection to display updated, meaningful content. However, it is functional offline for displaying your local files such as replays and installed patches. Most other features require an internet connection.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Phone notifications from Command Post... that's a thing?</p>
            <p>Enabling notifications for this website on your phone's browser will allow you to receive Command Post's notifications on your phone. You must be a registered Command Post user for this to work.</p>
            <div>
              <img style={{'height': '150px'}} src="images/cp_notifs.png"></img>
              <img style={{'height': '300px', 'margin': '5px'}} src="images/cp_notifs1.png"></img>
            </div>
          </div>

          <br />

          <div id='faq-troubleshooting' className='wt-700 faq-subtitle'>
            <p>
              <a href='#faq-troubleshooting'>Troubleshooting</a>
            </p>
          </div>

          <div>
            <p className='wt-500'>Command Post fails to download a 'metadata' file at launch or on clicking Refresh, and shows that it is in 'Offline Mode'.</p>
            <p>
              This is most likely caused by Command Post being restricted by your anti-virus program. If using Windows Defender, be sure to check 'Controlled Folder Access' settings and add Command Post as an exception, or disable that setting entirely.
            </p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Command Post doesn't start up at all, OR returns a .NET Framework error.</p>
            <p>
              Command Post should work on Windows Vista and above, but requires the Microsoft .NET Framework 4.5 or higher to run. If your .NET Framework isn't up to date (or isn't installed, which may be the case with Wine or Windows emulators), you can get it from the Microsoft website here. If Command Post still fails to launch, it's likely being blocked by anti-virus, and you must whitelist it on your anti-virus program and possibly re-install Command Post.              
            </p>
          </div>

          <br />

          <div>
            <p className='wt-500'>I installed Command Post but can't see it running.</p>
            <p>By default, Command Post minimizes to your system tray icon on the bottom-right. You can restore it by clicking the icon. Right-clicking the tray icon also has some other useful features like toggling Notifications on/off or quickly starting up the game.</p>
            <img src="images/cp_sysicon.png" />
          </div>

          <br />

          <div>
            <p className='wt-500'>I can't create an account on Command Post! It says my account has already been registered by a different user.</p>
            <p>You may have been blacklisted incorrectly by Command Post to prevent duplicate account creation. Contact the admin for help @ Command Post Discord <a href="https://discord.gg/vcEaQaR">here</a>.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>I get an 'Illegal Access' error when trying to Register or clicking Refresh.</p>
            <p>Try restarting Command Post to see if it fixes the problem. If not, it is likely caused by an error with Windows' WMI service. Try the following to fix it:
              <ul>
                <li>Open CMD/Command Prompt as Admin. You can do this by typing CMD on your Windows search, right-clicking on CMD/Command Prompt and selecting 'Run as Admin'.</li>
                <li>Once CMD opens, run this command on it: winmgmt /resetrepository</li>
                <li>Restart your PC. If the reset was successful, the 'Illegal Access' error should no longer appear on Command Post.</li>
              </ul>
            </p>
            <p>For further assistance, ask for help @ Command Post Discord <a href="https://discord.gg/vcEaQaR">here</a></p>
          </div>

          <br />

          <div>
            <p className='wt-500'>The 'Start Game' button on Command Post doesn't seem to work.</p>
            <p>Launching the game from Command Post is not required; it is purely for convenience. You may launch the game however you want based on your installation (Eg Steam/Origin, desktop shortcut etc). If you face problems with launching your game, try Command Post's Troubleshooter tool (refer to the question below).</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Command Post spams notifications when I receive private messages or updates. Can I disable it?</p>
            <p>Right-clicking the Command Post tray icon on the bottom-right of the taskbar will show you the option of toggling Notifications on/off temporarily. Alternatively, you may select 'Minimal Notifications' from the Options menu so only critical notifications are shown.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>I constantly get a 'Failed to access path, check permissions' error/notification from Command Post. How can I get rid of it?</p>          
            <p>Command Post requires write access to Documents\CommandPost directory in order for work correctly. Access to this directory may be manually allowed via folder properties-&gt;Security. Alternatively, you may try re-installing Command Post or running the Troubleshooter tool.</p>
          </div>

          <br />
          
          <div>
              <p className='wt-500'>How do I play on Command Post VPN?</p>
              <p>Click the 'VPN Servers' tab on Command Post. Follow the instructions to install OpenVPN. Once OpenVPN is installed, you simply click 'Connect' on one of the VPN servers and play over the 'Network' mode in-game.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Command Post isn't showing any of my multiplayer matches on my Matches History, OR Command Post's Local Replays isn't showing my replays, OR I get a 'invalid nickname' error when joining Tournaments, OR I see a red exclaimation mark over my Replay/Matches tabs.</p>
            <p>These problems may occur if you have faulty registry values, which may occur commonly with Origin/Steam versions. Command Post's Troubleshooter Tool does a great job of automatically detecting and fixing these errors. <i>As a bonus, it also allows you to run KW without having TW installed!</i></p>
            <p>Click Help &#8594 Troubleshooter Tool on Command Post and give it a shot.</p>
            <img style={{'height': '200px'}} src="images/troubleshooter.png" />
          </div>

          <br />

          <div id='faq-security' className='wt-700 faq-subtitle'>
            <p>
              <a href='#faq-security'>Security/Anti-Virus FAQs</a>
            </p>
          </div>          

          <div>
            <p className='wt-500'>Command Post is classified as a malware/Trojan by my Anti-Virus. Is it true?</p>
            <p>This is a false/incorrect detection. Command Post is a 100% clean application with no viruses or malicious behavior.</p>
            <p>Anti-virus programs' detection patterns are constantly always changing based on the current security landscape, and this may cause them to randomly (and incorrectly) flag Command Post as a threat.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Windows Defender just blocked Command Post and/or removed it from my PC. How can I restore it?</p>
            <p>Windows Defender-&gt;Protection History-&gt;Threat Blocked/Quarantined/Removed-&gt;Restore</p>
            <div>
              <img style={{'height': '350px'}} src='images/av_restore.png'></img>
            </div>
            <p>If this doesn't work, you may have to re-install Command Post.</p>
          </div>

          <br />

          <div>
            <p className='wt-500'>Which folders of my computer does Command Post have access to?</p>
            <p>Command Post has read/write access to the following directories on your disk:
              <ul>
                <li>Your game installation folder: Used for launching game and reading/modifying core game files</li>
                <li>Your game's sub-folder within Documents: Used for reading and reading/modifying your game options/preferences</li>
                <li>Command Post's sub-folder within Documents: Used for storing your Command Post preferences and other internal cache data</li>
                <li>Your game's maps/mods folders (customizable on Command Post): Used for reading/modifying your installed game maps/mods</li>
              </ul>
            </p>
            <p>Command Post has no access to any other files on your system.</p>
          </div>         

          <br />
          
          <div>
            <p className='wt-500'>Command Post is classified as a 'Wacatac' Trojan (disguiser, steals information, locks files) by my Anti-Virus. Is it true?</p>
            <p>This is a false/incorrect detection. Command Post has no access to your personal information or system files, and is completely safe to use.</p>
            <p>It does however read certain device hardware identifiers to prevent creation of fake/duplicate accounts; this may be falsely conflated with malicious behavior by anti-virus systems.</p>
          </div>

          <br />

          <div>
              <p className='wt-500'>My anti-virus says Command Post is potentially a Crypto-mining tool. Is it true?</p>
              <p>This is a false/incorrect detection. Command Post does not use your system for any malicious activity such as background Crypto-mining.</p>
              <p>Command Post does perform the following actions which anti-virus systems may falsely associate with Cryto-miners:
                <ul>
                  <li>Checksum Calculations: Command Post uses typical hashing functions to check the integrity and versioning of your installed game/patch files. Cypto-miners may also use similar functions, causing anti-viruses to falsely associate Command Post as a crypto-miner.</li>
                  <li>Resource Usage: When Command Post is launched, it reads and indexes all your game files. This may take between 2-4 minutes and can consume some CPU/Disk resources, but stops soon after. Anti-virus programs may falsely associate this temporarily high resource usage as mining activity.</li>
                </ul>
              </p>
          </div>
          
          <br />

          <div>
              <p className='wt-500'>My anti-virus says Command Post is potentially a BitTorrent/uTorrent Bundler. Is it true?</p>
              <p>This is a false/incorrect detection. Command Post does not use your system for any malicious activity such as creating or distributing illegal files such as Torrents.</p>
              <p>Command Post does perform the following actions which anti-virus systems may falsely associate with Torrent Bundlers:
                <ul>
                  <li>Patch Installers: Command Post allows mod/map creators to publish their content via Command Post as ZIP archives. Command Post allows them to either package their own EXE installers, or a simple 7ZIP archive which Command Post can extract into the aforementioned supported folders.</li>
                  <li>Automatic Content Updates: While Command Post can silently extract updates (from ZIP archives) for your mod/map files, it does so only in the folders specified above. Command Post also cannot run any EXEs without your explicit consent.</li>
                  <li>Self-Updates: Command Post can silently update itself, but these updates are always submitted and cleared by Anti-Virus vendors before being published. New updates for Command Post are also phased/gradually rolled out to users to ensure high quality and minimal bugs.</li>
                </ul>
              </p>
          </div>          

          <br />


        </div>
      </div>
    );
  }
}

export default CP;
