import React, { Component } from 'react';
import { Nav, NavItem, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import LoginPopup from '../components/LoginPopup.js';
import DonationsPopup from '../components/DonationsPopup.js';
import Helpers from '../Helpers.js';
import Popup from '../components/Popup.js';
import PaypalSubscribeButton from '../components/PaypalSubscribeButton.js';
import PaypalDonateButton from '../components/PaypalDonateButton.js';
import { Progress } from 'reactstrap';
import './Root.css';
import 'w3-css/w3.css';

import { connect } from "react-redux";
import { fetchConvos } from "../redux-actions/convos.js";
import { fetchDonations } from "../redux-actions/donations.js";
import { fetchTransactions } from '../redux-actions/transactions.js';
import { fetchMetadata } from '../redux-actions/metadata.js';
import { onPushFailure } from '../redux-actions/misc.js';

import { initializePush } from '../initializePush.js';
import CustomButton from './CustomButton.js';



class Root extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoginModal: [false],
      showPopup: [false],
      popupText: '',
      deferredPrompt: [null],
    };

    this.onLoginClick = this.onLoginClick.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onConvosClick = this.onConvosClick.bind(this);
    this.onDonationHistoryClick = this.onDonationHistoryClick.bind(this);
    this.onSubscribeClick = this.onSubscribeClick.bind(this);
    this.onPushFailed = this.onPushFailed.bind(this);
    this.requestPushPermission = this.requestPushPermission(this);
  }

  onLoginClick() {
    if (Helpers.isLoggedIn()) {
      localStorage.removeItem('token');
      window.location.reload()
    } else {
      this.state.showLoginModal[0] = true;
      this.setState({'showLoginModal': this.state.showLoginModal});
    }
  }

  onConvosClick() {
    this.props.history.push('/convos');
  }

  onLoginSuccess(msg) {
    window.location.reload();
  }

  onDonationHistoryClick() {
    this.props.dispatch(fetchTransactions())
  }

  onSubscribeClick() {
    this.props.history.push('/subscribe');
  }

  onPushFailed(error) {
    this.props.dispatch(onPushFailure(error))
  }

  requestPushPermission() {
    const isMobileScreen = window.matchMedia("only screen and (max-width: 760px)").matches;
    if (isMobileScreen && Helpers.isMobile()) {
      window.addEventListener('beforeinstallprompt', (defPrompt) => {

        defPrompt.preventDefault();

        if(window.confirm("Install KW-UPLOADS as an app? This will add it to your apps list and homescreen/desktop.")) {
          defPrompt.prompt();
          defPrompt.userChoice
            .then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');            
              } else {
                console.log('User dismissed the A2HS prompt');
              }
            });        
        }        
      });
    }    
  }

  componentDidMount() {
    if (Helpers.isLoggedIn()) {
      initializePush(this.onPushFailed);
    }
  }

  render() {
    const timeDiffConvos = (new Date().getTime() - this.props.convosTimestamp) / 1000;
    const convosLoading = this.props.convosLoading;
    if (!convosLoading && timeDiffConvos > 60) {
      this.props.dispatch(fetchConvos());
    }

    const donationsLoaded = this.props.donationsLoaded;
    const donationsLoading = this.props.donationsLoading;
    const timeDiffDonations = (new Date().getTime() - this.props.donationsTimestamp) / 1000;
    if (!donationsLoaded && !donationsLoading && timeDiffDonations > 30) {
      this.props.dispatch(fetchDonations());
    }

    const metadataLoaded = this.props.metadataLoaded;
    const metadataLoading = this.props.metadataLoading;
    const timeDiffMetadata = (new Date().getTime() - this.props.metadataTimestamp) / 1000;
    if (!metadataLoaded && !metadataLoading && timeDiffMetadata > 3600) {
      this.props.dispatch(fetchMetadata())
    }

    const { convos } = this.props;

    var unreadText = null;
    var unread = 0;
    if (convos && convos.length) {
      for (var i = 0; i < convos.length; i++ ) {
        const pos = i;
        unread += Number(convos[pos].unread_messages);
      }
    }
    if (unread > 0) unreadText = `${unread} message(s)`

    const unreadStr = unread > 9 ? '9+' : '' + unread;

    const messagesDiv = unread > 0 ? 
      (<Tooltip title={unreadText}>
        <div className='tooltip1'>
          <img className='img-pm' src={require('../res/pm.png')} />
        </div>
      </Tooltip>)
     : (<img className='img-pm' src={require('../res/pm.png')} />)    

    const pushError = this.props.pushError
    const pushErrorDiv = pushError ? (
      <div>
          <Tooltip title={pushError}>
            <div className='tooltip1'>
              <img className='img-pm' src={require('../res/caution.png')} onClick={this.requestPushPermission}  />
            </div>
          </Tooltip>
      </div>
    ) : (
      []
    )    

    const username = localStorage.getItem('username');
    const avatar = Helpers.loadAvatar(localStorage.getItem('avatar'));
    const loginDiv = Helpers.isLoggedIn() ? (
      <div>
        <div className='d-flex'>
          <div className='col-8 d-flex justify-content-end'>
            <div id='loginelement4' className='div-push-error mr-2'>
              {pushErrorDiv}
            </div>
            <div id='loginelement3' className='d-inline-flex div-pm position-relative' onClick={this.onConvosClick}>
              {messagesDiv}
              {unread > 0 &&
                <div className='circle-small position-absolute'>
                  {unreadStr}
                </div>
              }
            </div>
          </div>
          <div id='loginelement2' className='col-4 root-div-profile justify-content-end'>
            <img className='root-img-profile' src={avatar} /><br/>
            <div className='root-div-profile-name'>{username}</div>
          </div>
        </div>
        <Button id='loginelement1' className='btn-logout' size='sm' onClick={this.onLoginClick}>LOGOUT</Button>
      </div>
    ) : (
      <div>
        <Button className='btn-login' color='primary' onClick={this.onLoginClick}>LOGIN</Button>
      </div>
    );

    const { donations } = this.props;
    var donationsGoal = 0, donationsCur = 0, donationsPerc = 0;
    var donationsTxt = '';
    if (donations && donations.goal > 0) {
      donationsGoal = donations.goal;
      donationsCur = donations.total_amount;
      donationsPerc = Math.floor((donationsCur * 100) / donationsGoal);
      donationsTxt = ` $${donationsCur} / $${donationsGoal}`;
    }

    return (
      <div>
        <div className='app-bg'>
          <div className='row w3-padding'>
            <div className='col-lg-6 col-xs-12'>
              <div className='app-title'>              
                K W - U P L O A D S
                <span className='app-title-small'>
                  Formerly CGF-UPLOADS
                </span>
              </div>
              <div className='mt-3'>Your ultimate portal for the latest C&C3 Kane's Wrath content - Patch 1.02+, Maps, Mods, Replays, Tournaments, Statistics and more!</div>
              <div className='mt-2'>
                <CustomButton icon={require('../res/discord.png')} color='gray' text='Join Discord!' onClick={() =>
                  window.open(Helpers.CP_DISCORD, '_target')
                } style={{display: 'inline'}}/>
              </div>
            </div>
            <div className='col-lg-3' />
            <div className='col-lg-3 col-xs-12 root-login-div'>
              <div className='w3-margin'>
                {loginDiv}
              </div>
            </div>
          </div>
        </div>
        <Nav tabs fill className='root-nav'>
            <NavLink to='/home' className='root-nav-link'>Home</NavLink>
            <NavLink to='/downloads' className='root-nav-link'>Downloads</NavLink>
            <NavLink to='/replays' className='root-nav-link'>Replays</NavLink>
            <NavLink to='/maps' className='root-nav-link'>Custom Maps</NavLink>
            <NavLink to='/users' className='root-nav-link'>Users</NavLink>
            <NavLink to='/chat' className='root-nav-link'><img src={require('../res/chat.png')} style={{'width': '25px', 'height': '25px', 'marginRight': '5px'}} />Public Chat</NavLink>            
            <NavLink to='/tournaments' className='root-nav-link'>Tournaments</NavLink>
            <NavLink to='/matches' className='root-nav-link'>Matches</NavLink>
            <NavLink to='/ladders' className='root-nav-link'>Ladders</NavLink>
            <NavLink to='/inteldb' className='root-nav-link'>IntelDB<sup className='err-text'>BETA</sup></NavLink>
            <NavLink to='/cp' className='root-nav-link'><img src={require('../res/cp.png')} style={{'width': '25px', 'height': '25px', 'marginRight': '5px'}} />Command Post</NavLink>
        </Nav>
        <div className='cp-banner-div'>
          <div>
            <a href={Helpers.CP_URL}>
              <img src={require('../res/cp.png')} style={{'width': '30px', 'height': '30px', 'marginRight': '15px'}} />
              Download Command Post!
            </a>
          </div>
          <div className='cp-info-div'>
            <span style={{'color': 'black'}}>The ultimate portal for all your Kane's Wrath needs! Get automatic patch updates, upload/browse replays, check replay APM/stats, host automated tournaments and much more!</span>
            <a href='/cp' style={{'margin': '10px'}}><Button color='info'>MORE INFO</Button></a>
          </div>
        </div>
        <div className='row'>
          <div className='donate-div col-7'>        
            <div className='donate-btn-div'>
              <div className='donate-span'>The services of this website and Command Post are free for everyone. Please consider donating to fund tournaments and more!</div>          
              <PaypalDonateButton />
            </div>
            <div className='donate-prog-div'>
              <span>Funding for this month:</span><span>{donationsTxt}</span>
              <span className='generic-link' style={{'marginLeft': '10px'}} onClick={this.onDonationHistoryClick}>How is it spent?</span>
              <Progress color='warning' value={donationsPerc}></Progress>
            </div>
          </div>
          <div className='subscribe-div col-5'>
            <div className='subscribe-btn-div'>
              <div className='subscribe-txt-div'>Become a Veteran Member with a small monthly subscription towards Command Post!</div>
              <Button color='info' onClick={this.onSubscribeClick} >Subscribe!</Button>
            </div>
          </div>
        </div>
        <LoginPopup modal={this.state.showLoginModal} onSuccess={this.onLoginSuccess} />
        <DonationsPopup modal={this.props.showTransactionsModal} data={this.props.transactions} />
        <Popup modal={this.state.showPopup} title='Info' text={this.state.popupText} />
      </div>
    );
  }

}

const mapStateToProps = state => ({
  pushError: state.misc.pushError,
  convos: state.convos.convos,
  convosErr: state.convos.error,
  convosTimestamp: state.convos.timestamp,
  convosLoading: state.convos.loading,
  donations: state.donations.donations,
  donationsErr: state.donations.error,
  donationsLoaded: state.donations.isLoaded,
  donationsTimestamp: state.donations.timestamp,
  donationsLoading: state.donations.loading,
  transactions: state.transactions.transactions,
  transactionsLoaded: state.transactions.isLoaded,
  transactionsTimestamp: state.transactions.timestamp,
  showTransactionsModal: state.transactions.showTransactionsModal,
  metadata: state.metadata.metadata,
  metadataErr: state.metadata.error,
  metadataTimestamp: state.metadata.timestamp,
  metadataLoaded: state.metadata.isLoaded,
  metadataLoading: state.metadata.loading
});

export default connect(mapStateToProps)(Root);