// ButtonContainer.js
import React from 'react';
import './CustomButton.css'; // Import your CSS file here

const CustomButton = ({color, text, icon, onClick, style}) => {
    const iconDiv = icon ? (<img src={icon}></img>) : null
    var btnClass = 'custom-btn'
    if (color == 'green') {
        btnClass += ' custom-btn-green'
    }
    else if (color == 'gray' || color == 'grey') {
        btnClass += ' custom-btn-grey'
    }
    else if (color == 'yellow') {
        btnClass += ' custom-btn-yellow'
    } else {
        btnClass += ' custom-btn-blue'
    }
    return (
        <div className={btnClass} style={style} onClick={onClick}>
        {iconDiv}
        {text}
      </div>
    );
};

export default CustomButton;